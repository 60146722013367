/* eslint-disable no-nested-ternary */
import React, { useEffect, useRef } from 'react';
import { Text, Card, Avatar, Grid, Group, Center, SimpleGrid} from '@mantine/core';
import { exportMedicalRecord } from '../../../fhirApi';
import { useMedplum } from '@medplum/react';
// import { IconCopy, IconThumbDown, IconThumbUp, IconVolume } from '@tabler/icons-react';

interface FhirBotProps {
  fhirBotDetails: any;
  instruction: string;
  loading: boolean;
  prompt: string;
  features: any;
  getPDFHTMLData: any;
  selectPatinetID: any;
  patientName: any;
}


const FhirBotDetails: React.FC<FhirBotProps> = (props: FhirBotProps) => {
  const titleRef = useRef<HTMLDivElement>(null);
  const bottomRef = useRef<HTMLDivElement>(null);
  const medplum = useMedplum();

  useEffect(() => {
    if (props.prompt) {
      titleRef?.current?.scrollIntoView({ behavior: 'smooth' });
    }
  }, [props.prompt]);

  useEffect(() => {
    bottomRef?.current?.scrollIntoView({ behavior: 'smooth' });
  }, [props.fhirBotDetails]);

  // Function to download the medical record
  const downloadMedicalRecordPdf = async (
    patientName: string,
    patientID: string,
    exportType: string,
    isPII: boolean
  ) => {
    const resourcesToExport: string[] = [];

    try {
      const response = await exportMedicalRecord(medplum, patientID, resourcesToExport, true, exportType, isPII);
      if (response && response.data) {
        let blob;
        let fileExtension;
        let mimeType;

        if (exportType === 'PDF') {
          mimeType = 'application/pdf';
          fileExtension = 'pdf';
        }

        blob = new Blob([response.data], { type: mimeType });
        const url = window.URL.createObjectURL(blob);
        if (exportType === 'PDF') {
          const a = document.createElement('a');
          a.href = url;
          a.download = `${patientName.replaceAll(' ', '_')}_Medical_Records.${fileExtension}`;
          a.click();
          window.URL.revokeObjectURL(url);
        }
        window.URL.revokeObjectURL(url);

        // Fetch and update clinical data immediately after download
      } else {
        throw new Error('Response is null or does not contain data');
      }
    } catch (error) {
      console.error('Error fetching medical record:', error);
    }
  };

  return (
    <>
      <Text sx={{ fontSize: '16px', fontWeight: 500, display: 'inline-block' }}>AI Assistant</Text>
      <Card className="card" mt={10} radius="sm" p="xs">
        {props.fhirBotDetails.length <= 1 ? (
          <>
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                height: 'fit-content',
              }}
            >
              {props.loading ? (
                <span className="loading">
                  <span className="loading__dot"></span>
                  <span className="loading__dot"></span>
                  <span className="loading__dot"></span>
                </span>
              ) : (
                <div style={{ maxWidth: '63%' }}>
                  <Center>
                    <img src="/img/icons/stars-02.svg" style={{ width: '6%', margin: '4%' }} />
                  </Center>
                  <SimpleGrid cols={4} spacing="sm" style={{ cursor: 'pointer' }}>
                    {props.features.map((feature: any, index: any) => (
                      <Card
                        key={index}
                        shadow="sm"
                        radius="md"
                        withBorder
                        onClick={(e) => props.getPDFHTMLData(e, feature.title)}
                      >
                        <Group>
                          {feature.icon}
                          <Text
                            sx={{
                              fontWeight: 400,
                              fontSize: '14px',
                              color: '#676767',
                              fontFamily: 'Inter',
                            }}
                          >
                            {feature.title}
                          </Text>
                        </Group>
                      </Card>
                    ))}
                  </SimpleGrid>
                </div>
              )}
            </div>
            {props.fhirBotDetails.map((item: any, index: number) => (
              <div
                className="inner-card"
                key={index}
                ref={props.prompt ? titleRef : null}
                style={{
                  marginTop: '20px',
                  color: '#101828',
                  fontWeight: 400,
                  fontSize: '14px',
                  fontFamily: 'Inter',
                  marginBottom: '2px',
                }}
              >
                <Grid>
                  <div className="d-flex align-start">
                    <div
                      style={{
                        marginTop: '15px',
                        color: '#101828',
                        fontWeight: 400,
                        fontSize: '14px',
                        fontFamily: 'Inter',
                      }}
                    >
                      <Avatar src="/img/icons/stars-02.svg" radius="xl" size="sm" />
                    </div>
                    <div
                      style={{
                        marginLeft: '10px',
                        marginBottom: '8px',
                        color: '#101828',
                        fontWeight: 400,
                        fontSize: '14px',
                        marginTop: '14px',
                        fontFamily: 'Inter',
                      }}
                    >
                      {item.description ? (
                        item.description.includes('<button>') ? (
                          <div>
                            <div
                              style={{
                                color: '#101828',
                                fontWeight: '400',
                                marginBottom: '4px',
                                fontFamily: 'Inter',
                                fontSize: '14px',
                              }}
                            >
                              You can download the generated PDF file using the link below.
                            </div>
                            <div>
                              <span
                                onClick={() =>
                                  downloadMedicalRecordPdf(props.patientName, props.selectPatinetID, 'PDF', true)
                                }
                                style={{
                                  color: '#2F67AD',
                                  cursor: 'pointer',
                                  fontWeight: 400,
                                  fontSize: '14px',
                                  fontFamily: 'Inter',
                                }}
                              >
                                {`Download ${props.patientName.replaceAll(' ', '_')}_Medical_Records.pdf`}
                              </span>
                            </div>
                          </div>
                        ) : (
                          <div
                            style={{
                              color: '#101828',
                              fontWeight: 400,
                              fontSize: '14px',
                              fontFamily: 'Inter',
                            }}
                            className="inner-card"
                            dangerouslySetInnerHTML={{ __html: item.description }}
                          />
                        )
                      ) : (
                        <span className="loading">
                          <span className="loading__dot"></span>
                          <span className="loading__dot"></span>
                          <span className="loading__dot"></span>
                        </span>
                      )}
                    </div>
                  </div>
                </Grid>
                {/* <Group
                  mt="md"
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    border: '1px solid #D5D8DE',
                    borderRadius: '6px',
                    width: 'fit-content',
                    gap: '1px',
                    marginLeft: '2%',
                  }}
                >
                  <Tooltip label="speak" position="bottom">
                    <ActionIcon>
                      <IconVolume size={17} color="#7D7D7D" />
                    </ActionIcon>
                  </Tooltip>
                  <Tooltip label="Like" position="bottom">
                    <ActionIcon>
                      <IconThumbUp size={17} color="#7D7D7D" />
                    </ActionIcon>
                  </Tooltip>
                  <Tooltip label="Dislike" position="bottom">
                    <ActionIcon>
                      <IconThumbDown size={17} color="#7D7D7D" />
                    </ActionIcon>
                  </Tooltip>
                  <Tooltip label="Copy" position="bottom">
                    <ActionIcon>
                      <IconCopy size={17} color="#7D7D7D" />
                    </ActionIcon>
                  </Tooltip>
                </Group> */}
              </div>
            ))}
          </>
        ) : (
          <>
            {props.fhirBotDetails.map((item: any, index: number) => (
              <div className="inner-card" key={index} ref={props.prompt ? titleRef : null}>
                {item.title && (
                  <div style={{ marginTop: '25px', display: 'flex', justifyContent: 'flex-end' }}>
                    <Grid>
                      <div
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                          marginBottom: '35px',
                          marginRight: '30px',
                          backgroundColor: '#F4F4F4',
                          padding: '5px',
                          marginTop: '5px',
                          borderRadius: '8px',
                          fontFamily: 'Inter',
                        }}
                      >
                        <Text
                          sx={{
                            marginLeft: '10px',
                            fontWeight: 400,
                            fontSize: '14px',
                            color: '#334054',
                            fontFamily: 'Inter',
                          }}
                        >
                          {item.title}
                        </Text>
                      </div>
                    </Grid>
                  </div>
                )}
                <Grid>
                  <div className="d-flex align-start">
                    <Avatar src="/img/icons/stars-02.svg" radius="xl" size="sm" />
                    <div
                      style={{
                        marginLeft: '10px',
                        marginBottom: '8px',
                        fontWeight: 400,
                        fontSize: '14px',
                        color: '#334054',
                        fontFamily: 'Inter',
                      }}
                    >
                      {item.description ? (
                        item.description.includes('<button>') ? (
                          <div>
                            <Text
                              sx={{
                                color: '#101828',
                                fontWeight: 400,
                                marginBottom: '4px',
                                fontSize: '14px',
                                fontFamily: 'Inter',
                              }}
                            >
                              You can download the generated PDF file using the link below.
                            </Text>
                            <div>
                              <span
                                onClick={() =>
                                  downloadMedicalRecordPdf(props.patientName, props.selectPatinetID, 'PDF', true)
                                }
                                style={{
                                  color: '#2F67AD',
                                  cursor: 'pointer',
                                  fontWeight: 400,
                                  fontSize: '14px',
                                  fontFamily: 'Inter',
                                }}
                              >
                                {`Download ${props.patientName.replaceAll(' ', '_')}_Medical_Records.pdf`}
                              </span>
                            </div>
                          </div>
                        ) : (
                          <div
                            style={{
                              color: '#101828',
                              fontWeight: 400,
                              fontSize: '14px',
                              fontFamily: 'Inter',
                              marginRight: '250px',
                              justifyContent: 'center',
                              textAlign: 'justify',
                            }}
                            className="inner-card"
                            dangerouslySetInnerHTML={{ __html: item.description }}
                          />
                        )
                      ) : (
                        <span className="loading">
                          <span className="loading__dot"></span>
                          <span className="loading__dot"></span>
                          <span className="loading__dot"></span>
                        </span>
                      )}
                    </div>
                  </div>
                </Grid>
                {/* {item.description && (
                  <Group
                    mt="md"
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                      border: '1px solid #D5D8DE',
                      borderRadius: '6px',
                      width: 'fit-content',
                      gap: '1px',
                      marginLeft: '2%',
                    }}
                  >
                    <Tooltip label="speak" position="bottom">
                      <ActionIcon>
                        <IconVolume size={17} color="#7D7D7D" />
                      </ActionIcon>
                    </Tooltip>
                    <Tooltip label="Like" position="bottom">
                      <ActionIcon>
                        <IconThumbUp size={17} color="#7D7D7D" />
                      </ActionIcon>
                    </Tooltip>
                    <Tooltip label="Dislike" position="bottom">
                      <ActionIcon>
                        <IconThumbDown size={17} color="#7D7D7D" />
                      </ActionIcon>
                    </Tooltip>
                    <Tooltip label="Copy" position="bottom">
                      <ActionIcon>
                        <IconCopy size={17} color="#7D7D7D" />
                      </ActionIcon>
                    </Tooltip>
                  </Group>
                )} */}
              </div>
            ))}
          </>
        )}
        <div ref={bottomRef} />
      </Card>
    </>
  );
};
export default FhirBotDetails;
