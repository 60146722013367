import { Space } from '@mantine/core';
import { MEDPLUM_VERSION } from '@medplum/core';
import { UserConfiguration } from '@medplum/fhirtypes';
import { AppShell, Loading, NavbarMenu, useMedplum } from '@medplum/react';
import { Suspense, useEffect, useState } from 'react';
import { useLocation, useSearchParams } from 'react-router-dom';
import { AppRoutes } from './AppRoutes';
import { getOrganization } from './fhirApi';
import './App.css';

export function App(): JSX.Element {
  const medplum = useMedplum();
  const config = medplum.getUserConfiguration();
  const location = useLocation();
  const [searchParams] = useSearchParams();
  const isLoggedIn = localStorage.getItem('isLoggedIn');
  const [imageURL, setImageURL] = useState<any>('/img/pragmaconnect-logo.svg');
  useEffect(() => {
    if (isLoggedIn) {
      medplum
        .get('auth/me')
        .then((response: any) => {
          const orgId = response.profile?.managingOrganization?.reference.split('/')[1];
          if (orgId) {
            getOrganization(medplum, orgId)
              .then((response: any) => {
                if (response.data?.extension) {
                  response.data?.extension.map((e: any) => {
                    if (e.url === 'http://organization.image') {
                      setImageURL(e.valueString);
                    }
                  });
                }
              })
              .catch((error: any) => {
                console.error('Error fetching data:', error);
              });
          }
        })
        .catch((err) => console.log(err));
    }
  }, [isLoggedIn]);
  if (medplum.isLoading()) {
    return <Loading />;
  }

  return (
    <AppShell
      logo={<img src={imageURL ? imageURL : '/img/pragmaconnect-logo.svg'} style={{ height: '24px' }} />}
      pathname={location.pathname}
      searchParams={searchParams}
      version={MEDPLUM_VERSION}
      menus={userConfigToMenu(config)}
      displayAddBookmark={!!config?.id}
    >
      <Suspense fallback={<Loading />}>
        <AppRoutes />
      </Suspense>
    </AppShell>
  );
}

function userConfigToMenu(config: UserConfiguration | undefined): NavbarMenu[] {
  const result =
    config?.menu?.map((menu) => ({
      title: menu.title,
      links:
        menu.link?.map((link) => ({
          label: getDisplayName(link.name as string).displayName,
          href: link.target as string,
          icon: getIcon(link.name as string),
        })) || [],
    })) || [];

  // result.push({
  //   title: 'Settings',
  //   links: [
  //     {
  //       label: 'Security',
  //       href: '/security',
  //       icon: <IconLock />,
  //     },
  //   ],
  // });

  return result;
}

const resourceTypeToIcon: Record<string, any> = {
  Dashboard: '../../img/dashboard-assets/IconHome.svg',
  Organization: '../../img/dashboard-assets/IconOrganization.svg',
  Practitioner: '../../img/dashboard-assets/IconPractitioners.svg',
  Patients: '../../img/dashboard-assets/IconUser.svg',
  Claims: '../../img/dashboard-assets/IconInsurrance.svg',
  Locations: '../../img/dashboard-assets/IconLocations.svg',
  Settings: '../../img/dashboard-assets/IconSettings.svg',
  Patient: '../../img/dashboard-assets/IconUser.svg',
  Appointment: '../../img/dashboard-assets/ic_appointment.svg',
  Medication: '../../img/dashboard-assets/ic_medication.svg',
  Observation: '../../img/dashboard-assets/ic_observation.svg',
  Procedure: '../../img/dashboard-assets/ic_procedure.svg',
  CarePlan: '../../img/dashboard-assets/ic_careplan.svg',
  Goal: '../../img/dashboard-assets/ic_goal.svg',
  Immunization: '../../img/dashboard-assets/ic_immunization.svg',
  DiagnosticReport: '../../img/dashboard-assets/ic_digno_report.svg',
  CareTeam: '../../img/dashboard-assets/ic_careteam.svg',
  AccessPolicy: '../../img/dashboard-assets/ic_access.svg',
  Project: '../../img/dashboard-assets/ic_project.svg',
  Batch: '../../img/dashboard-assets/ic_batch.svg',
  SuperConfig: '../../img/dashboard-assets/ic_superConfig.svg',
  Security: '../../img/dashboard-assets/ic_security.svg',
  Filters: '../../img/dashboard-assets/filter.svg',
  EhrIntegration: '../../img/dashboard-assets/sso-white.svg',
  AIAssistant: '../../img/dashboard-assets/fhir-bot-white.svg',
  Assessment: '../../img/dashboard-assets/assessment_white.svg',
  AIConsultations: '../../img/dashboard-assets/ai -consult.svg',
  Telehealth: '../../img/dashboard-assets/telehealth.svg',
};

function getIcon(name: string): JSX.Element | undefined {
  try {
    if (name in resourceTypeToIcon) {
      const Icon = resourceTypeToIcon[name];
      return <img src={Icon} style={{ height: '16px', marginRight: '10px', color: '#FFF', width: '20px' }} />;
    } else if (name === 'Claims & Benefits') {
      const Icon = resourceTypeToIcon['Claims'];
      return <img src={Icon} style={{ height: '16px', marginRight: '10px', color: '#FFF', width: '20px' }} />;
    }
  } catch (e) {
    // Ignore
  }
  return <Space w={30} />;
}

const resourceTypeToDisplayName: Record<string, string> = {
  Dashboard: 'Dashboard',
  Organization: 'Organizations',
  Practitioner: 'Practitioners',
  Patients: 'Patients',
  Claims: 'Claims & Benefits',
  Locations: 'Locations',
  Settings: 'Settings',
  Patient: 'Patients',
  Appointment: 'Appointments',
  Medication: 'Medications',
  Observation: 'Observations',
  Procedure: 'Procedures',
  CarePlan: 'Care Plans',
  Goal: 'Goals',
  Immunization: 'Immunizations',
  DiagnosticReport: 'Diagnostic Reports',
  CareTeam: 'Care Teams',
  AccessPolicy: 'Access Policy',
  Project: 'Project',
  Batch: 'Batch',
  SuperConfig: 'Super Config',
  Security: 'Security',
  Filters: 'Advanced Search',
  EhrIntegration: 'EHR Integration',
  AIAssistant: 'AI Assistant',
  Assessment: 'Assessments',
  AIConsultations: 'AI Consultations',
  Telehealth: 'Telehealth',
};

function getDisplayName(name: string) {
  let displayName = name;
  if (name in resourceTypeToDisplayName) {
    displayName = resourceTypeToDisplayName[name];
  }
  return { displayName };
}
