import { Card, Text, Group, createStyles, Skeleton, Box, Grid, Center, Pagination } from '@mantine/core';
import { useEffect, useState } from 'react';
import { useMedplum } from '@medplum/react';
import { format } from 'date-fns';
import Each from './Each';
import TitleComponent from './TitleComponent';
import axios from '../utils/axios';

const useStyles = createStyles((theme) => ({
  card: {
    backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[7] : theme.white,
  },

  section: {
    padding: theme.spacing.sm,
    borderBottom: `1px solid #EEF1F6 !important`,
  },

  footer: {
    padding: theme.spacing.md,
    borderTop: `1px solid #EEF1F6 !important`,
  },

  scrollbar: {
    overflow: 'hidden',
    '&:hover': {
      overflowY: 'auto',
      scrollbarWidth: 'thin',
      scrollbarColor: '#ccc #f3f3f3',
      '&::-webkit-scrollbar': {
        width: '8px',
      },
      '&::-webkit-scrollbar-thumb': {
        background: '#ccc',
        borderRadius: '8px',
        '&:hover': {
          background: '#aaa',
        },
      },
    },
  },

  badge: {
    minWidth: '80px',
    minHeight: '30px',
    color: '#000000',
    textTransform: 'capitalize',
    fontWeight: 600,
  },
}));

export function AllNotifications(props: any) {
  const { classes } = useStyles();
  const medplum = useMedplum();
  const [notificationResponse, setNotificationResponse] = useState([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isDataFound, setIsDataFound] = useState<boolean>(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPageCount, setTotalPageCount] = useState<any>();
  const perPage = 20;
  
  const formatDate = (dateString: string): string => {
    const date = new Date(dateString);
    return format(date, 'MM/DD/YYYY hh:mm A');
  };

  const fetchData = async () => {
    const token = await medplum.getAccessToken();
    const offset = (currentPage-1)*perPage;
    try {
      setIsLoading(true);
      setNotificationResponse([]);
      setIsDataFound(false);
      // const response = await getNotifications(medplum);
      const response = await axios.get(
        `fhir/R4/AuditEvent?_count=20&_offset=${offset}&_sort=-_lastUpdated&_total=accurate`,
        {
          headers: {
            Accept: 'application/fhir+json',
            Authorization: 'Bearer ' + token,
          },
        }
      );
      if (response?.data.entry.length !== 0) {
        setTotalPageCount(response?.data.total);
        setNotificationResponse(response?.data.entry || []);
      } else {
        setIsDataFound(true);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, [props.orgId, currentPage]);

  return (
    <>
      <TitleComponent title="All Notifications" />
      <Box px="lg" py="sm">
        <Grid mb="md">
          <Grid.Col span={12} sm={12} lg={12}>
            <Card withBorder radius="md" className={classes.card}>
              <Card.Section px="lg" display="flex" className={classes.section}>
                <Group style={{ justifyContent: 'flex-start', flex: 8, paddingRight: '40px' }}>
                  <Text className="darkText" fw={700}>
                    Notifications
                  </Text>
                </Group>
                <Group style={{ justifyContent: 'flex-start', flex: 4 }}>
                  <Text className="darkText" fw={700}>
                    Date
                  </Text>
                </Group>
              </Card.Section>

              <Group
                px="xs"
                className={classes.scrollbar}
                display="block"
                style={{ height: '450px', overflowX: 'hidden' }}
              >
                <Each
                  of={notificationResponse}
                  render={(item: any) => (
                    <Card.Section display="flex" className={classes.section}>
                      <Group style={{ justifyContent: 'flex-start', flex: 8, paddingRight: '40px' }}>
                        <Text
                          className="lightText"
                          fw={500}
                          style={{ cursor: 'pointer' }}
                        >
                          {item?.resource.outcomeDesc}
                        </Text>
                      </Group>
                      <Group style={{ justifyContent: 'flex-start', flex: 4 }}>
                        <Text className="lightText" fw={500}>
                          {formatDate(item?.resource.recorded)}
                        </Text>
                      </Group>
                    </Card.Section>
                  )}
                />
                {isDataFound && (
                  <Group style={{ justifyContent: 'center', flex: 1, position: 'relative', top: '50%' }}>
                    <Text className="darkText" fw={700}>
                      No Data Found
                    </Text>
                  </Group>
                )}
                {isLoading && (
                  <div style={{ marginTop: '10px' }}>
                    <Skeleton height={8} radius="xl" />
                    <Skeleton height={8} mt={6} radius="xl" />
                    <Skeleton height={8} mt={6} width="70%" radius="xl" />
                  </div>
                )}
              </Group>
            </Card>
          </Grid.Col>
        </Grid>
        <Center m="md" p="md" style={{ justifyContent: 'flex-end' }}>
          {totalPageCount && (
            <Pagination
              className="pagination"
              value={currentPage}
              total={Math.ceil(totalPageCount / perPage)}
              onChange={setCurrentPage}
            />
          )}
        </Center>
      </Box>
    </>
  );
}
