/* eslint-disable @typescript-eslint/explicit-function-return-type */
import React, { useEffect, useRef, useState, forwardRef, useCallback } from 'react';
import TitleComponent from '../../../components/TitleComponent';
import './FhirBot.css';
import { Avatar, Box, Button, ActionIcon, Title, Textarea, Loader, Group, Text } from '@mantine/core';
import FhirBotDetails from './FhirBotDetails';
// import { getSSOConfig } from '../../../config';
import { ResourceAvatar, useMedplum } from '@medplum/react';
import SpeechRecognition, { useSpeechRecognition } from 'react-speech-recognition';
import { Project, Patient } from '@medplum/fhirtypes';
import { GoogleGenerativeAI } from '@google/generative-ai';
import { getAiAssistantPatientData } from '../../../fhirApi';
import { showNotification } from '@mantine/notifications';
import {
  IconSearch,
  IconMicrophone,
  IconPlayerStopFilled,
  IconFileText,
  IconCircleCheck,
  IconListDetails,
} from '@tabler/icons-react';
import { formatHumanName, getDisplayString, isUUID } from '@medplum/core';
import { HeaderSearchTypes, toKey, toOption } from '../../../../../react/src/AppShell/HeaderSearchInput';
import {
  AsyncAutocomplete,
  AsyncAutocompleteOption,
} from '../../../../../react/src/AsyncAutocomplete/AsyncAutocomplete';

export interface AIconfig {
  chatgpt_model: string;
  chatgpt_api_key: string;
  chatgpt_api_url: string;
  gemini_api_key: string;
  gemini_model: string;
}

const defaultQuery = (input: string) => {
  const escaped = JSON.stringify(input);
  if (isUUID(input)) {
    return `{
      Patients1: PatientList(_id: ${escaped}, _count: 1) {
        resourceType
        id
        name {
          given
          family
        }
        birthDate
      }
      ServiceRequestList(_id: ${escaped}, _count: 1) {
        resourceType
        id
        subject {
          display
        }
      }
    }`;
  }
  return `{
    Patients1: PatientList(name: ${escaped}, _count: 5) {
      resourceType
      id
      name {
        given
        family
      }
      birthDate
    }
    ServiceRequestList(identifier: ${escaped}, _count: 5) {
      resourceType
      id
      subject {
        display
      }
    }
  }`;
};

// To Do - Add the Abstraction for GhatGPT and Gemini API & use SDK for the same
const FhirBot = () => {
  const audioChunksRef = useRef<Blob[]>([]);
  const mediaRecorderRef = useRef<MediaRecorder | null>(null);
  const intervalRef = useRef<any>(null);
  const [recording, setRecording] = useState(false);
  const ProfileIcon = <Avatar src="../../../../static/img/dashboard-assets/profile.svg" radius="xl" size="sm" />;
  const [instruction, setInstruction] = useState('');
  const [prompt, setPrompt] = useState('');
  const [fhirBotDetails, setFhirBotDetails] = useState<any[]>([]);
  const [loading, setLoading] = useState(false);
  const { transcript, resetTranscript } = useSpeechRecognition();
  const inputRef = React.createRef<HTMLTextAreaElement>();
  const [messages, setMessages] = useState([
    {
      role: 'system',
      content: 'You are a helpful assistant.',
    },
  ]);
  const [geminiMessages, setGeminiMessages] = useState('Your custom string here');
  // const config = getSSOConfig();
  const medplum = useMedplum();
  const [aiConfig, setAIConfig] = useState<AIconfig>();
  const [selectedOption, setSelectedOption] = useState('chatgpt');
  const aiModel = useRef('chatgpt');
  const [project, setProject] = useState<Project | undefined>();
  const [selectedPatient, setSelectedPatient] = useState<string | null>(null);
  const [patients, setPatients] = useState<{ label: string; value: string }[]>([]);
  const [patientData] = useState<any>({});
  const features = [
    {
      icon: <IconFileText size={30} color="#CD3131" />,
      title: 'Retrieve all medical records & export as PDF.',
    },
    {
      icon: <IconCircleCheck size={30} color="#58CE96" />,
      title: 'Check if the patient is eligible for any ongoing clinical trials.',
    },
    {
      icon: <IconFileText size={30} color="#FFA620" />,
      title: "Give me the patient's progress report.",
    },
    {
      icon: <IconListDetails size={30} color="#8255E1" />,
      title: "Retrieve the list of patient's current and past medications.",
    },
  ];

  const getSecretConfig = (project: Project, secretName: string) => {
    const tempConfig: any = {};
    project?.secret?.forEach((s: any) => {
      if (s.name.startsWith(secretName)) {
        const key = s.name; // remove the secretName and trailing underscore
        tempConfig[key] = s.valueString;
      }
    });
    return tempConfig;
  };

  const fetchProjectConfig = async () => {
    if (project) {
      const secretName = aiModel.current === 'chatgpt' ? 'chatgpt' : 'gemini';
      aiModel.current = aiModel.current === 'chatgpt' ? 'chatgpt' : 'gemini';
      const tempConfig = getSecretConfig(project, secretName);
      setAIConfig(tempConfig as AIconfig);
    } else {
      try {
        const projectDetails = await medplum.getProject();
        if (projectDetails) {
          await medplum.get(`/admin/projects/${projectDetails.id}`).then((projectConfig) => {
            const project = projectConfig.project as Project;
            setProject(project);
            const secretName = aiModel.current === 'chatgpt' ? 'chatgpt' : 'gemini';
            const tempConfig = getSecretConfig(project, secretName);
            setAIConfig(tempConfig as AIconfig);
          });
        }
      } catch (error) {
        console.error('Error fetching project config:', error);
      }
    }
  };

  useEffect(() => {
    fetchProjectConfig();
  }, []);

  useEffect(() => {
    if (selectedPatient && patients.length > 0) {
      const patientName = patients?.find((patient: any) => patient?.value === selectedPatient)?.label;
      const defaultMessage = `How can I help you with ${patientName}`;
      setFhirBotDetails([{ description: defaultMessage, loading: false }]);
    }else{
      setFhirBotDetails([]);
    }
  }, [selectedPatient]);

  let getPDFHTMLData: any;
  if (aiModel.current === 'chatgpt') {
    getPDFHTMLData = async (e: any, userQuestion?: string) => {
      // Check if the event is a keyboard event or a click event
      const isEnterKeyPress = e?.key === 'Enter';
      const isClickEvent = e?.type === 'click'; // Remove userQuestion check for click event

      // Proceed only if it's an Enter keypress or a valid click event
      if (isEnterKeyPress || isClickEvent) {
        if (!selectedPatient) {
          showNotification({ color: 'red', message: 'Please select a patient' });
          return;
        }

        // Add the user's instruction to the UI with loading state
        setFhirBotDetails((prevData) => [...prevData, { title: userQuestion || instruction, loading: true }]);

        setInstruction(''); // Reset instruction input
        setLoading(true); // Set loading state

        const sanitizedUserQuestion = userQuestion
          ? userQuestion.replace(/&/g, 'and')
          : instruction.replace(/&/g, 'and');
        const response = await getAiAssistantPatientData(medplum, selectedPatient as string, sanitizedUserQuestion);
        const data = response?.data;

        // Update state to reflect the response
        setLoading(false);
        setFhirBotDetails((prevData) =>
          prevData.map((item) =>
            item.title === (userQuestion || instruction) && item.loading
              ? { ...item, description: data, loading: false }
              : item
          )
        );
      }
    };
  }

  const handleInputChange = (event: { target: { value: React.SetStateAction<string> } }) => {
    setInstruction(event.target.value);
    resetTranscript();
  };
  const startRecording = () => {
    SpeechRecognition.startListening();
    navigator.mediaDevices
      .getUserMedia({ audio: true })
      .then((stream) => {
        const mediaRecorder = new MediaRecorder(stream);
        mediaRecorder.ondataavailable = (e) => {
          if (e.data.size > 0) {
            audioChunksRef.current.push(e.data);
          }
        };
        mediaRecorder.onstop = () => {
          audioChunksRef.current = [];
          if (inputRef.current) {
            inputRef.current.focus();
          }
        };

        audioChunksRef.current = [];
        mediaRecorder.start();
        setRecording(true);
        mediaRecorderRef.current = mediaRecorder;
      })
      .catch((error) => {
        console.error('Error accessing microphone:', error);
      });
  };

  const stopRecording = () => {
    if (mediaRecorderRef.current) {
      mediaRecorderRef.current.stop();
      clearInterval(intervalRef.current);
      setRecording(false);
      const audioStream = mediaRecorderRef.current.stream;
      audioStream.getTracks()[0].stop();
      SpeechRecognition.stopListening();
      setInstruction(transcript);
    }
  };

  if (aiModel.current === 'chatgpt') {
    async (e: any) => {
      if (e.key === 'Enter' || (e.type === 'click' && instruction)) {
        if (!selectedPatient) {
          showNotification({ color: 'red', message: 'Please select Patient' });
          return;
        }

        // console.log(patientData);
        setPrompt(instruction);
        setLoading(true);
        setFhirBotDetails((prevData) => [...prevData, { title: instruction, loading: true }]);
        setInstruction('');
        resetTranscript();

        const url = aiConfig?.chatgpt_api_url || '';
        const headers = {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${aiConfig?.chatgpt_api_key}`,
        };

        let content;
        const instr = instruction.toLowerCase();
        if (instr) {
          content = `Convert following data into a flat html readable table? Use important 7 fields as columns, Make atleast 5 rows is it is possible to create dont duplicate data/rows. Table should not have borders.header background color should be always this #f5fafaf do not add any other color. Always give response in tabular format.
          This are my instructions : ${instruction} and generate the response for the patient data : ${patientData} 
          Please ensure that no additional symbols such as${'*'}, ${'**'}, ${'#'}, ${'##'}, ${'###'}, ${'####'}, ${'#####'}, ${'######'}, ${'-'} etc., are added in the generated notes at any point unless they are present in the input. Each section should start with a new line, and the section subpoints should be without any additional symbols. 
          /n
           If medication ,Active medication , historical medication is mentioned in the response, please include a URL hyperlink in the medication name column that redirects to the medication details page. Medication: <a href="${location.origin}/MedicationRequest/{medicationId}" target="_blank">{medicationName}</a> 
          `;

          const systemMessageForSummary = {
            role: 'system',
            content: `You are an expert in FHIR specification and can summarize the plain text data included in the attachments 
            of the FHIR resources and ignore the documents that do not contain relevant medical information.
            If the attachment is encoded as base64, decode it and provide a concise summary without unnecessary information and redundancies.
            The summary should contain details such as medical conditions, medications, allergies, procedures, progress notes, 
            and other relevant information formatted in unordered list. Use medical terms for the summary. This are my instructions : ${instruction} and generate the response for the patient data : ${patientData} `,
          };

          const newMessage = {
            role: 'user',
            content: content,
          };
          let payload;
          if (instr.includes('summarize') || instr.includes('summary') || instr.includes('insights')) {
            console.log('system working');
            payload = [...messages, systemMessageForSummary];
            // if (clinicalNoteRequest) {
            //   payload = [...messages, systemMessageForClinicalNotes, newMessage];
            // }
          } else {
            payload = [...messages, newMessage];
          }

          const data = {
            model: aiConfig?.chatgpt_model,
            messages: payload.slice(-4),
            stream: false,
          };

          const tableResult = await fetch(url, {
            method: 'POST',
            headers: headers,
            body: JSON.stringify(data),
          });

          let dataResult: any;
          try {
            const tableChat = await tableResult.json();
            dataResult = tableChat?.choices[0]?.message?.content;
          } catch (e) {
            setLoading(false);
            setFhirBotDetails((prevData) =>
              prevData.map((item) =>
                item.title === instruction
                  ? {
                      ...item,
                      description: 'Unable to process your request at this time. Please try again later.',
                      loading: false,
                    }
                  : item
              )
            );
            return;
          }

          const responseConversation = [...payload];
          setMessages(responseConversation);
          setLoading(false);

          if (dataResult.includes('```html')) {
            dataResult = dataResult.replaceAll('```html', '').replaceAll('```', '');
          }
          const matchResult = dataResult.match(/<table[\s\S]*\/table>/);
          if (!matchResult) {
            setFhirBotDetails((prevData) =>
              prevData.map((item) =>
                item.title === instruction && item.loading ? { ...item, description: dataResult, loading: false } : item
              )
            );
          } else {
            // if (clinicalNoteRequest) {
            //   matchResult[0] = dataResult;
            // }
            setFhirBotDetails((prevData) =>
              prevData.map((item) =>
                item.title === instruction && item.loading
                  ? { ...item, description: matchResult[0], loading: false }
                  : item
              )
            );
          }
        } else {
          setLoading(false);
          setFhirBotDetails((prevData) =>
            prevData.map((item) =>
              item.instruction === instruction ? { ...item, description: '', loading: false } : item
            )
          );
        }
      }
    };
  } else if (aiModel.current === 'gemini') {
    async (e: any) => {
      const handleErrorResponse = (errorMessage: string) => {
        setLoading(false);
        setFhirBotDetails((prevData) =>
          prevData.map((item) =>
            item.title === instruction
              ? {
                  ...item,
                  description: errorMessage,
                  loading: false,
                }
              : item
          )
        );
      };

      if (e.key === 'Enter' || (e.type === 'click' && instruction)) {
        if (!selectedPatient) {
          showNotification({ color: 'red', message: 'Please select Patient' });
          return;
        }
        setPrompt(instruction);
        setLoading(true);
        setFhirBotDetails((prevData) => [...prevData, { title: instruction, loading: true }]);
        setInstruction('');
        resetTranscript();

        // const systemMessage = `You're an expert in FHIR specification, crafting perfect queries for the Medplum FHIR server.
        //   Familiar with Medplum's query formats outlined in the documentation ${config.promptDoc}, you adeptly map common words to their FHIR equivalents.
        //   You add relevant LOINC code to the 'type' parameter of the search query if needed.
        //   Your responses always consist of a FHIR query URL in JSON format, specifically {'url': '[query]'}, and count parameters
        //   only if mentioned in the instruction.
        //   \n
        //   Can you give FHIR query for: ${instruction}?`;

        // const conversation = systemMessage;

        // setGeminiMessages(conversation);

        try {
          const genAI = new GoogleGenerativeAI(`${aiConfig?.gemini_api_key}`);
          const model = genAI.getGenerativeModel({ model: `${aiConfig?.gemini_model}` });
          // const geminiPrompt = systemMessage;
          // const result = await model.generateContent(geminiPrompt);
          // const response = (await result.response) as any;

          // let textResponse: any;
          // try {
          //   const chat = await response.text();
          //   // Remove code block formatting
          //   const cleanedResponse = chat.replace(/```json|```/g, '').trim();
          //   const responseData = JSON.parse(cleanedResponse);
          //   // Extract the URL
          //   const url = responseData.url;
          //   textResponse = url;
          // } catch (e) {
          //   handleErrorResponse('Unable to process your request at this time. Please try again later.');
          //   return;
          // }

          // const responseConversation = conversation;
          // setGeminiMessages(responseConversation);

          // if (textResponse.includes('fhir/R4/')) {
          //   let request: any;
          //   try {
          //     if (textResponse.includes('fhir/R4/')) {
          //       request = textResponse?.replace(/'/g, '"');
          //       console.log('gemini request:', request);
          //     } else {
          //       request = textResponse?.replace('https://api.medplum.com/', '');
          //     }
          //   } catch (error) {
          //     console.error('Error parsing JSON:', error);
          //     handleErrorResponse('Unable to process your request at this time. Please try again later.');
          //     return;
          //   }

          // let clinicalNoteRequest = false;
          // if (request.indexOf('DocumentReference') !== -1 && request.indexOf('type=11488-4') !== -1) {
          //   clinicalNoteRequest = true;
          // }

          // let fhirqueryResult: any = '';
          // try {
          //   fhirqueryResult = await medplum.get(`${request}`);
          // } catch (e) {
          //   handleErrorResponse('Unable to process your request at this time. Please try again later.');
          //   return;
          // }

          // const fhirqueryResponse = fhirqueryResult;
          // let entryString = '';

          // if (fhirqueryResponse.resourceType === 'Bundle') {
          //   entryString = JSON.stringify(fhirqueryResponse.entry.slice(0, 5))
          //     .replaceAll('https://app.pragmaconnect.com:8103/fhir/R4', 'https://app.pragmaconnect.com')
          //     .replaceAll('/Patient/', '/Patient/patient-dashboard/');
          // } else {
          //   entryString = JSON.stringify(fhirqueryResponse)
          //     .replaceAll('https://app.pragmaconnect.com:8103/fhir/R4', 'https://app.pragmaconnect.com')
          //     .replaceAll('/Patient/', '/Patient/patient-dashboard/');
          // }

          // if (!entryString || entryString === '[]') {
          //   handleErrorResponse('No Data Found');
          //   return;
          // }

          // let notes = '';
          // if (clinicalNoteRequest) {
          //   const bundle = JSON.parse(entryString);
          //   // Sort the bundle array by lastUpdated in descending order
          //   bundle.sort((resource1: any, resource2: any) => {
          //     const dateA = new Date(resource1.resource.meta.lastUpdated);
          //     const dateB = new Date(resource2.resource.meta.lastUpdated);
          //     return dateA.getTime() - dateB.getTime();
          //   });
          //   for (const bundleItem of bundle) {
          //     const resource = bundleItem.resource;
          //     if (resource.content?.[0].attachment?.data) {
          //       const base64String = resource.content[0].attachment.data;
          //       const decodedString = atob(base64String);
          //       const date = new Date(resource.meta.lastUpdated).toLocaleString();
          //       notes += 'Date: ' + date + '\n' + decodedString + '\n\n';
          //     }
          //   }
          // }

          let content;
          const instr = instruction.toLowerCase();
          if (instr) {
            //   content = `${instruction} : ${entryString}`;
            //   if (clinicalNoteRequest) {
            //     content = `Write a clinical progress report in textual format timewise.
            //     The client has visited the doctor for multiple appointments over time.
            //     Below are the clinical notes timewise: ${notes} \n\n Please identify any improvements and regressions made by the patient during this time.
            //     Format the progress report like such:

            //     <b>Progress Notes</b>
            //     [Client name] has visited recently for [Number of appointments within date range] appointments.
            //     <b>Subjective</b>
            //     [Insert summary of subjectives like how it updated timewise]
            //     <b>Improvements and/or regressions</b>
            //     [Improvements and/or regressions in subjective and objective measures. Report subjective measures in sentences.
            //     Always give objective measures in html table tags like <table></table> with three columns in the order of: outcome measure.
            //     The rows in the table are the different objective outcome measures reported.]
            //     <b>Plan</b>
            //     The current treatment plan includes [Insert summary of Plan notes from the most recent session].
            //     `;
            //   }
            // } else {
            content = `Convert following data into a flat html readable table? Use important 7 fields as columns and atleast 5 rows is it is possible to create dont duplicate data/rows. Table should not have borders.header background color should be always this #f5fafaf. Always give response in tabular format.
              /n This are my instructions : ${instruction} and generate the response for the patient data : ${patientData} 
              /n Please ensure that no additional symbols such as${'*'}, ${'**'}, ${'#'}, ${'##'}, ${'###'}, ${'####'}, ${'#####'}, ${'######'}, ${'-'} etc., are added in the generated notes at any point unless they are present in the input. Each section should start with a new line, and the section subpoints should be without any additional symbols. 
              /n Only give answers from avilable data with considering given instruction dont give answer which is not realted to instruction. Dont add anything by yourself or which is not in data or which is not in instruction. If data is not avaiable give a message that "No data present for this instruction" .
              /n
               If and only if user asks for medication or Active medication or historical medication then please include a URL hyperlink in the medication name column that redirects to the medication details page. Medication: <a href="${location.origin}/MedicationRequest/{medicationId}" target="_blank">{medicationName}</a> 
              `;
            // }
            const systemMessageForSummary = `You are an expert in FHIR specification and can summarize the plain text data included in the attachments 
              of the FHIR resources and ignore the documents that do not contain relevant medical information.
              If the attachment is encoded as base64, decode it and provide a concise summary without unnecessary information and redundancies.
              The summary should contain details such as medical conditions, medications, allergies, procedures, progress notes, 
              and other relevant information formatted in unordered list. Use medical terms for the summary. This are my instructions : ${instruction} and generate the response for the patient data : ${patientData} `;

            // const systemMessageForClinicalNotes = `You always give response textual format. Do not give any FHIR query. Always give objective measures in html table tags like <table style='line-height:20px'></table> having <th></th> for header and <tr></tr> for each measure.
            //   You must not use any other html tags except <table></table> for the output result`;
            const newMessage = content;
            let payload;
            if (instr.includes('summarize') || instr.includes('summary') || instr.includes('insights')) {
              payload = `${geminiMessages} \n ${systemMessageForSummary}`;
              // payload = `${geminiMessages} \n ${systemMessageForSummary} \n ${newMessage}`;

              // if (clinicalNoteRequest) {
              //   payload = `${geminiMessages} \n ${systemMessageForClinicalNotes} \n ${newMessage}`;
              // }
            } else {
              payload = `${geminiMessages} \n ${newMessage}`;
            }

            // const data = content;
            // const geminiPrompt = data;
            const result = await model.generateContent(payload);
            const tableResult = (await result.response) as any;

            let dataResult: any;
            try {
              const tableChat = await tableResult.text();
              dataResult = tableChat;
            } catch (e) {
              handleErrorResponse('Unable to process your request at this time. Please try again later.1');
              return;
            }

            const responseConversation = payload;
            setGeminiMessages(responseConversation);
            setLoading(false);
            if (dataResult.includes('```html')) {
              dataResult = dataResult.replaceAll('```html', '').replaceAll('```', '');
            }
            const matchResult = dataResult.match(/<table[\s\S]*\/table>/);
            if (!matchResult) {
              setFhirBotDetails((prevData) =>
                prevData.map((item) =>
                  item.title === instruction && item.loading
                    ? { ...item, description: dataResult, loading: false }
                    : item
                )
              );
            } else {
              // if (clinicalNoteRequest) {
              //   matchResult[0] = dataResult;
              // }
              setFhirBotDetails((prevData) =>
                prevData.map((item) =>
                  item.title === instruction && item.loading
                    ? { ...item, description: matchResult[0], loading: false }
                    : item
                )
              );
            }
          } else {
            handleErrorResponse('');
          }
        } catch (error) {
          console.error('Error:', error);
          handleErrorResponse('Unable to process your request at this time. Please try again later.');
        }
      }
    };
  }

  const handleOptionChange = async (optionName: string) => {
    setSelectedOption(optionName);
    if (optionName === 'chatgpt') {
      aiModel.current = 'chatgpt';
    } else {
      aiModel.current = 'gemini';
    }
    await fetchProjectConfig();
  };

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.style.height = 'auto';
      inputRef.current.style.height = `${inputRef.current.scrollHeight}px`;
    }
  }, [instruction, transcript]);

  const loadPatients = useCallback(
    async (input: string, signal: AbortSignal) => {
      const query = defaultQuery(input);
      try {
        const response = await medplum.graphql(query, undefined, undefined, { signal });
        const patientList = getResourcesFromResponse(response, input);
        // Ensure that patient.id is never undefined

        return patientList || [];
      } catch (error) {
        console.error('Error fetching patients:', error);
        return [];
      }
    },
    [medplum]
  );

  console.log('loadpatients', loadPatients);

  const ItemComponent = forwardRef<HTMLDivElement, AsyncAutocompleteOption<Patient>>(
    ({ resource, ...others }: AsyncAutocompleteOption<Patient>, ref) => {
      return (
        <div ref={ref} {...others}>
          <Group noWrap>
            <ResourceAvatar value={resource} />
            <div>
              <Text>{getDisplayString(resource)}</Text>
              <Text size="xs" color="dimmed">
                {resource.birthDate}
              </Text>
            </div>
          </Group>
        </div>
      );
    }
  );

  function getResourcesFromResponse(response: any, query: string): Patient[] {
    const resources = [];
    if (response.data.Patients1) {
      resources.push(...response.data.Patients1);
    }
    return resources
      .sort((a: Patient, b: Patient) => {
        return getResourceScore(b, query) - getResourceScore(a, query);
      })
      .slice(0, 5);
  }

  function getResourceScore(resource: Patient, query: string): number {
    let bestScore = 0;
    if (resource.name) {
      for (const name of resource.name) {
        bestScore = Math.max(bestScore, getStringScore(formatHumanName(name), query));
      }
    }
    return bestScore;
  }

  function getStringScore(str: string | undefined, query: string): number {
    if (!str) {
      return 0;
    }
    const index = str.toLowerCase().indexOf(query.toLowerCase());
    if (index < 0) {
      return 0;
    }
    return 100 - index;
  }

  const handleSelect = useCallback((selected: Patient[]) => {
    if (selected && selected.length === 1) {
      const selectedPatientId = selected[0]?.id || '';
      setSelectedPatient(selectedPatientId);

      const label = selected[0]?.name?.[0]?.family
        ? selected[0]?.name?.[0]?.given?.join(' ') + ' ' + selected[0]?.name?.[0]?.family
        : selected[0]?.name?.[0]?.given?.join(' ') || 'this Patient';

      setPatients([{ label: label, value: selectedPatientId }]);
    } else {
      console.error('Expected exactly one patient to be selected');
    }
  }, []);

 

  return (
    <>
      <Box px="lg" pt="sm" sx={{ paddingBottom: 0 }}>
        <TitleComponent title={''} />
      </Box>
      <Box px="lg" pt="sm" className="fhir-bot">
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            backgroundColor: '#F8FAFC',
            justifyContent: 'space-between',
            marginBottom: '1rem',
            padding: '12px, 24px, 12px, 24px',
          }}
        >
          <Box mb="md" mt={15} ml={10}>
            <AsyncAutocomplete
              size="sm"
              radius="md"
              icon={<IconSearch size={16} />}
              placeholder="Search patients"
              loadOptions={loadPatients}
              // onChange={(item: Patient[]) =>  handleSelect(item)}
              onChange={(item: HeaderSearchTypes[]) => {
                if (item.length === 0) {
                  setPatients([]);
                  setSelectedPatient('');
                } else {
                  handleSelect(item as Patient[]);
                }
              }}
              itemComponent={ItemComponent}
              clearSearchOnChange
              clearable={false}
              toKey={toKey} // Ensure `toKey` uses `id` for uniqueness
              toOption={toOption} // Correctly pass the option as is
              maxSelectedValues={1}
              sx={{
                '.mantine-i2bv4f ': {
                  backgroundColor: 'white',
                  marginTop: '9px',
                },
               

                '.mantine-122becb': {
                  position : 'absolute',
                  left : '87%',
             
                },

                inputWrapper: {
                  display: 'flex',
                  alignItems: 'center', // Aligns the icon and input text
                  position: 'relative', // Keeps the icon within the input box
                },
                input: {
                  width: selectedPatient ? '250px' : '250px', // Default width
                  paddingTop: '6px',
                  marginTop: '-3px',
                  transition: selectedPatient ? 'none' : 'width 0.2s', // No transition if patient is selected
                  '&:focus-within': {
                    width: selectedPatient ? '250px' : '350px', // Expanded width on focus if no patient is selected
                  },
                },
                root: {
                  display: 'flex',
                  alignItems: 'center', // Ensures everything is aligned properly
                },
                icon: {
                  marginRight: '8px', // Space between the icon and the input
                  verticalAlign: 'middle', // Ensures the icon aligns with the input text
                },
                cursor: 'text',
              }}
            />
          </Box>

          <Box sx={{ display: 'flex', width: '195px', height: '40px', marginRight: '12px' }}>
            <Button
              onClick={() => handleOptionChange('chatgpt')}
              variant="filled"
              sx={(theme) => ({
                borderRadius: '4px 0 0 4px',
                backgroundColor: selectedOption === 'chatgpt' ? '#16416C' : '#FFFFFF',
                color: selectedOption === 'chatgpt' ? '#FFFFFF' : 'black',
                '&:hover': {
                  backgroundColor: selectedOption === 'chatgpt' ? '#16416C' : '#FFFFFF',
                },
                padding: '8px 16px 8px 16px',
                width: '97px',
                height: '40px',
                border: '1px solid #D0D5DD',
              })}
            >
              ChatGPT
            </Button>
            <Button
              onClick={() => handleOptionChange('gemini')}
              variant="filled"
              sx={(theme) => ({
                borderRadius: '0 4px 4px 0',
                backgroundColor: selectedOption === 'gemini' ? '#16416C' : '#FFFFFF',
                color: selectedOption === 'gemini' ? '#FFFFFF' : 'black',
                '&:hover': {
                  backgroundColor: selectedOption === 'gemini' ? '#16416C' : '#FFFFFF',
                },
                padding: '8px 16px 8px 16px',
                width: '97px',
                height: '40px',
                border: '1px solid #D0D5DD',
              })}
            >
              Gemini AI
            </Button>
          </Box>
        </Box>

        {/* <Box px="lg" pt="sm" className="fhir-bot"> */}
        <FhirBotDetails
          fhirBotDetails={fhirBotDetails}
          instruction={instruction}
          loading={loading}
          prompt={prompt}
          features={features}
          getPDFHTMLData={getPDFHTMLData}
          selectPatinetID={selectedPatient}
          patientName={patients[0]?.label}
        />
        {/* <Text sx={{ fontSize: '16px', fontWeight: 500, margin: '8px 0' }}>Instructions</Text>
        <Input
          icon={ProfileIcon}
          placeholder="Write / Record your instruction"
          value={instruction || transcript}
          onChange={handleInputChange}
          onKeyDown={generateDynamicNote}
          ref={inputRef}
          rightSection={
            <div className="d-flex g-12 input-icon">
              {!recording ? (
                <Tooltip label="Start Recording">
                  <Avatar
                    src="../../../../img/dashboard-assets/mic-icon.svg"
                    radius="xl"
                    size="sm"
                    sx={{ cursor: 'pointer' }}
                    onClick={() => startRecording()}
                  />
                </Tooltip>
              ) : (
                <Tooltip label="Stop Recording">
                  <Avatar
                    src="../../../../img/dashboard-assets/ic_stop.svg"
                    radius="xl"
                    size="sm"
                    sx={{ cursor: 'pointer' }}
                    onClick={stopRecording}
                  />
                </Tooltip>
              )}
              <Avatar
                src="../../../../img/dashboard-assets/upload-icon.svg"
                size="sm"
                sx={{ cursor: 'pointer' }}
                onClick={generateDynamicNote}
              />
            </div>
          }
        /> */}
        <Title order={4} mt="md" mb="xs">
          Instructions
        </Title>
        <div
          style={{
            display: 'flex',
            width: '100%',
            border: '1px solid #D0D5DD',
            borderRadius: '8px',
            padding: '12px, 14px 12px 14px',
            height: 'auto',
          }}
        >
          <div style={{ marginTop: '7px', marginRight: '6px', marginLeft: '9px' }}>{ProfileIcon}</div>
          <Textarea
            variant="unstyled"
            style={{ width: '100%', height: 'auto' }}
            color="#667085"
            placeholder="Write / Record your instruction"
            value={instruction || transcript}
            onChange={handleInputChange}
            ref={inputRef}
            minRows={3} // Adjusts height as per content
            onKeyDown={(e) => {
              if (e.key === 'Enter' && !e.shiftKey) {
                e.preventDefault(); // Prevent default to avoid form submission
                if (instruction.trim() !== '') {
                  getPDFHTMLData(e);
                }
              }
            }}
          />
          <div
            style={{
              display: 'flex',
              alignItems: 'flex-end',
              justifyContent: 'flex-end',
              marginRight: '1rem',
              gap: '8px',
              marginBottom: '8px',
            }}
          >
            {!recording ? (
              <ActionIcon variant="subtle" onClick={() => startRecording()}>
                <IconMicrophone size={23} />
              </ActionIcon>
            ) : (
              <div style={{ display: 'flex', gap: '8px' }}>
                <ActionIcon variant="subtle" onClick={() => stopRecording()}>
                  <IconPlayerStopFilled size={23} />
                </ActionIcon>
                <ActionIcon variant="subtle">
                  <Loader size={23} color="black" variant="dots" />
                </ActionIcon>{' '}
              </div>
            )}
            <Button
              component="span"
              bg={'#16416C'}
              disabled={loading}
              onClick={(e) => {
                if (instruction.trim() !== '') {
                  getPDFHTMLData(e);  
                  resetTranscript();
           
              }
              }}
              style={{ marginLeft: '0.5rem', height: '35px', borderRadius: '8px', borderColor: '#16416C' }} // Set height
            >
              {loading ? 'Sending...' : 'Send'}
            </Button>
          </div>
        </div>
      </Box>
    </>
  );
};

export default FhirBot;
