/* eslint-disable @typescript-eslint/prefer-optional-chain */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
import React, { useState } from 'react';
import { Text, Card, Tooltip, Avatar, Textarea, Modal, Checkbox, Grid, Button } from '@mantine/core';
import { IconInfoCircleFilled } from '@tabler/icons-react';
import { showNotification } from '@mantine/notifications';
import axios from '../../../utils/axios';
import { useMedplum } from '@medplum/react';
import { getEHRTokenFromLocalStorage } from '../../../utils/util';

interface ClinicalNoteProps {
  isOpen: boolean;
  handleClose: () => void;
  clinicalNote: any;
  appointmentId: any;
  document: any;
}
const ClinicalNotePreview = (props: ClinicalNoteProps) => {
  const medplum = useMedplum();
  const [clinicalNote, setClinicalNote] = useState(props.clinicalNote);

  const handleTextareaChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    setClinicalNote(event.currentTarget.value);
  };

  const handleSubmit = async () => {
    const token = await medplum.getAccessToken();

    const postData: any = {};
    postData.clinicalnote = clinicalNote;
    postData.appointmentId = props.appointmentId;
    postData.documentReferenceId = props.document?.id;

    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
        ehr_token: getEHRTokenFromLocalStorage(),
      },
    };

    const updateBody: any = {};
    updateBody.clinicalnote = clinicalNote;
    updateBody.documentId = props.document?.id;
    if (props.document?.id) {
      try {
        await axios.put(`/api/pragmaconnect/update-clinical-note`, updateBody, config);
        props.handleClose();
      } catch (error) {
        console.log(error);
      }
    } else {
      try {
        const postBody: any = {};
        postBody.clinicalnote = clinicalNote;
        postBody.appointmentId = props.appointmentId;
        await axios.post(`/api/pragmaconnect/create-clinical-note`, postBody, config);
        props.handleClose();
      } catch (error) {
        console.log(error);
      }
    }
    const response: any = await axios.post(`/ehr/epic/create-clinical-note-appointment`, postData, config);
    if (response && response.data.docRefId) {
      showNotification({ color: 'green', message: 'Your Clinical Note has been submitted successfully.' });
      props.handleClose();
    } else {
      showNotification({ color: 'green', message: 'Your Clinical Note has been submitted successfully.' });
      props.handleClose();
    }
  };
  return (
    <>
      <Modal
        opened={props.isOpen}
        onClose={props.handleClose}
        title={
          <Text sx={{ fontSize: '16px', fontWeight: 500, display: 'inline-block' }}>
            Clinical Notes Preview
            <Tooltip
              label="User can edit clinical notes directly."
              transitionProps={{ transition: 'pop', duration: 300 }}
            >
              <IconInfoCircleFilled
                size={20}
                style={{ marginLeft: '5px', color: '#828282', verticalAlign: 'middle' }}
              />
            </Tooltip>
          </Text>
        }
        style={{
          backgroundColor: '#F5F5F5',
          color: '#333',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
        size="55rem"
        className="clinical-note"
      >
        <Card display="block" mt={10} mb={10} radius="sm" p="xs" sx={{ border: '1px solid #BDBDBD', padding: '20px' }}>
          <div>
            <div className="d-flex">
              <Avatar src="../../../../img/dashboard-assets/bot.svg" radius="xl" size="sm" />
              <Text sx={{ marginLeft: '10px', fontWeight: 700, color: '#2F67AD' }}>PCBot</Text>
            </div>
            <Textarea minRows={12} sx={{ border: 'none', padding: '0 25px' }} onChange={handleTextareaChange}>
              {props.clinicalNote}
            </Textarea>
          </div>
        </Card>
        <Checkbox size="sm" defaultChecked= {true} label="Submit to EHR" />
        <Grid pl={10} mt={20}>
          <Button sx={{ backgroundColor: '#BDBDBD' }} onClick={props.handleClose}>
            Cancel
          </Button>
          <Button sx={{ marginLeft: '10px', backgroundColor: '#3673B6' }} onClick={handleSubmit}>
            Save
          </Button>
        </Grid>
      </Modal>
    </>
  );
};

export default ClinicalNotePreview;
